import React, { useState } from 'react';
import useAuth, { AuthContext } from './AuthContext';

function AuthProvider({ children,authProvider }) {

  let [user, setUser] = React.useState(null);

  let signin = (cred, callback) => {
    return authProvider.signin( cred,(user) => {
      setUser(user);
      callback(user);
    });
  };

  let signout = (callback) => {
    return authProvider.signout(() => {
      setUser(null);
      callback();
    });
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;