import React, { useState } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import CsvDownload from 'react-json-to-csv';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowUp,faArrowDown } from '@fortawesome/free-solid-svg-icons'

// import { Navigate, useNavigate } from "react-router-dom";

function ListTable(props) {
    let [state, setState] = useState({
        
        data: props.data,
        paginationParams: {
            recPerPage: 10,
            noOfRecords: 0,
            noOfPages: 0,
            currentPage: 1
        },
        colHeads: props.colHeads,// || ['UserId','name','age'],
        /** props.actionButtons = [{buttonName:'edit',callback:editButtonCallback}] */
        actionButtons: props.actionButtons// ||  []   
    });

    const [currentSortColumn,setCurrentSortColumn] = useState("");
    
    const [currentOrder,setcurrentOrder] = useState("");
    const pageChangeCallback = (newPageNumber) => {
        const prev = state;
        setState({...prev,paginationParams:{...prev.paginationParams,currentPage:newPageNumber}});
    }
////////////// Begin for Table column Sorting functionality //////////////////
    const { items, requestSort,sortConfig } = useSortableData(props.data);
    const HandlesortChange = (e) => {
        e.preventDefault();
        requestSort(e.target.dataset.columnname,e.target.dataset.type);
        setCurrentSortColumn(e.target.dataset.columnname);
        if(sortConfig == null )
        {
            setcurrentOrder("ascending");
        }
        else{
            setcurrentOrder( sortConfig.key === e.target.dataset.columnname && sortConfig.direction === 'ascending' ? "descending":"ascending");
        }
    }
    
    //On column display sort order icon
    // const getcurrentSortOrder = (name) => {
    //     if (!sortConfig) {
    //       return;
    //     }
    //     return sortConfig.key === name ? sortConfig.direction : undefined;
    //   };

////////////// End for Table column Sorting functionality //////////////////

    return (
        <Container style={{ maxWidth: "100% !important"}}>
            <Row>
                <Col className="offset-10 mb-2">
                {props.data && props.data.length > 0 && <CsvDownload data-testid="download" className="btn btn-success" style={{float:"right"}} data={props.data} />}
                </Col>
            </Row>
            <Table striped bordered hover responsive className="w-100">
                {/* Table Head*/}
                <THead className="table-dark" thitems={state.colHeads} ab={state.actionButtons.length > 0} colOnClick={HandlesortChange} currentsortcolumn={currentSortColumn} currentsortorder={currentOrder}/>
                {/* Table Rows*/}
                <TBody tbitems={
                    items.slice((state.paginationParams.currentPage-1)*state.paginationParams.recPerPage,
                    state.paginationParams.currentPage*state.paginationParams.recPerPage)} 
                    actionButtons={state.actionButtons}  headercount={state.colHeads.length}/>
                {/* Table Footer*/}
                
            </Table>
            <TablePagination params={state.paginationParams} noRecords={props.data.length} pageChangeCallback={pageChangeCallback} />
        </Container>
    )
}

function THead(props) {
    //click event on column for soring
    const ths = props.thitems.map((thitem, i) => <Th key={i} colOnClick={props.colOnClick} {...props}>{thitem}</Th>);
    return (
        <thead className={props.className}>
            <TRow>
                {ths}
                {props.ab && <Th>Actions</Th>}
            </TRow>

        </thead>
    )
}

function TBody(props) {
    if(props.tbitems.length > 0 ){
        let tbs  = props.tbitems.map((tbitem, i) => <TRow key={i} cols={tbitem} actionButtons={props.actionButtons} />);
    
        return (
            <tbody>
                {tbs}

            </tbody>
        )
    }
    else{
        return (
            <tbody>
                 <tr>
                     <td colSpan={props.headercount}>No Records Found</td>
                </tr>
            </tbody>
        )
    }
}

function TRow(props) {
    
    const cols = Object.values(props.cols || {}).map((col, i) => <TCol key={i} index={i}>{col}</TCol>)
    return (
        <tr>
            {cols}
            <ActionButtons actionButtons={props.actionButtons} rowData={props.cols} />
            {props.children}
        </tr>
    )
    
}   

function Th(props) {
    return (
        //On column add click, data type and column name for the sorting
        <th onClick={props.colOnClick} data-columnname={props.children.value} data-type={props.children.type}>
            {props.children.heading}
            {props.children.value === props.currentsortcolumn && props.children !== "Actions"? <FontAwesomeIcon icon={props.currentsortorder === "ascending"? faArrowUp:faArrowDown} /> : <></>}
        </th>
    )
}

function TCol(props) {
    let isDate = props.index === 9;
    let isExpire = false;
    if(isDate)
    {
        let currentdate = new Date();
        isExpire = currentdate > new Date(props.children);
    }
    return (
        <td style={{ background: isExpire ? 'bisque': ''}}>
            {props.children}
        </td>
    )
}

function ActionButtons(props) {

    if (props.actionButtons && props.actionButtons.length > 0) {
        return (<TCol key="action">
            {props.actionButtons.map((ab,index) => {
                switch (ab.buttonName) {
                    case 'edit' :
                        return <EditButton key={index} rowData={props.rowData} callback={ab.callback} />;
                    case 'keyList':
                        return <KeyListButton key={index} rowData={props.rowData} callback={ab.callback} />;
                    case 'keyHistory':
                        return <KeyHistoryButton key={index} rowData={props.rowData} callback={ab.callback} />;
                    case 'delete':
                        return <DeleteButton key={index} rowData={props.rowData} callback={ab.callback} />;
                    default:
                        return "";
                }

            })}
        </TCol>
        );
    }
}

function DeleteButton({rowData,callback}) {
    const handleClick = (e) => {
        // console.log(e.target);
        callback(rowData);
    }
    return (
        <Button variant="link" onClick={handleClick}>Delete</Button>
    )
}

function EditButton({rowData,callback}) {
    //let navigate = useNavigate();
    const handleClick = (e) => {
        callback(rowData);
        // e.preventDefault();
       
    }
    return (
        <Button variant="link" onClick={handleClick}>Edit</Button>
    )
}

function KeyListButton({rowData,callback}) {
    //let navigate = useNavigate();
    const handleClick = (e) => {
        callback(rowData);
        // e.preventDefault();
       
    }
    return (
        <Button variant="link" onClick={handleClick}>Key Log</Button>
    )
}


function KeyHistoryButton({rowData,callback}) {
    //let navigate = useNavigate();
    const handleClick = (e) => {
        callback(rowData);
        // e.preventDefault();
       
    }
    return (
        <Button variant="link" onClick={handleClick}>View History</Button>
    )
}
function TablePagination(props) {
    const params = props.params;
    params.noOfRecords = props.noRecords;
    params.noOfPages = Math.ceil(params.noOfRecords/params.recPerPage);
    let active = params.currentPage;
    let items = [];

    const handleClick = (e) => {
        params.currentPage = parseInt(e.target.text);
        props.pageChangeCallback(params.currentPage);
    }

    for (let number = 1; number <= params.noOfPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === active} onClick={handleClick}>
                {number}
            </Pagination.Item>, 
        );
    }

    return (
       <Row>
        <Col>
        {`Displaying ${(params.currentPage -1)*params.recPerPage + 1} to ${params.currentPage * params.recPerPage} of ${params.noOfRecords}`}
        </Col>
        <Col>
        <Pagination className="justify-content-end">{items}</Pagination>
        </Col>
       </Row>
            
       
    );
}

////////////// Begin for Table column Sorting method //////////////////
function useSortableData(items, config = null){
    const [sortConfig, setSortConfig] = React.useState(config);
    
    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if(sortConfig.type === "date")
          {
            if (new Date(a[sortConfig.key]) < new Date(b[sortConfig.key])) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (new Date(a[sortConfig.key]) > new Date(b[sortConfig.key])) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
          }
          else{
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);
  
    const requestSort = (key,type) => {
      let direction = 'ascending';
      if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      setSortConfig({ key, direction ,type});
    }
  
    return { items: sortedItems, requestSort,sortConfig };
  }
////////////// End for Table column Sorting method //////////////////
export default ListTable;