import { Col, Container, Row } from 'react-bootstrap';
import SideBar from '../SideBar/sidebar';
import './docslayout.css';
function DocsLayout(props) {
    const sblinks = [{link:'Sale',isBookMark:true,href:'#operations-tag-Sale'},
    {link:'Property',isBookMark:true,href:'#operations-tag-Property'},
    {link:'Saleshistory',isBookMark:true,href:'#operations-tag-Saleshistory'}
    
    // const sblinks = [{link:'pets',isBookMark:true,href:'#operations-tag-pet'},
    // {link:'store',isBookMark:true,href:'#operations-tag-store'}
];
    return (
        <div>
            <Container fluid>
                <Row>
                    <Col md={2} className="p-0">
                        <SideBar sblinks={sblinks} />
                    </Col>
                    <Col className="p-2">
                        {props.children}
                    </Col>
                </Row>
            </Container>




        </div>
    );
}

export default DocsLayout;
