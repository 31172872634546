import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './customerlist.css'
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ListTable from '../../components/ListTable/listtable';
//import { AUTH_API_BASE_URL } from '../../globals';

export default function CustomerList() {
    const [tableData, setTableData] = useState([]);
    const [colHeads, setColHeads] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        //get data from db for the list table
        axios.get(`${process.env.REACT_APP_AUTH_API_BASE_URL}/user/GetCustomerlist`,
            {
                headers: {
                    'apiKey': 'TestABC123'
                }
            })
            .then((res) => {
                // console.log(res.data)
                setTableData(res.data);
                setColHeads([
                    { heading: 'UserId', value: 'UserId' },
                    { heading: 'Reference Id', value: 'ReferenceId' },
                    { heading: 'Company name', value: 'UserCompany' },
                    { heading: 'First Name', value: 'UserFirstName' },
                    { heading: 'Last Name', value: 'UserLastName' },
                    { heading: 'Status', value: 'Status' },
                    { heading: 'IsAutoRenew', value: 'IsAutoRenew' },
                    { heading: 'Current Account Type', value: 'TierAccessKey' },
                    { heading: 'Next Account Type', value: 'NextTierAccessKey' },
                    // 'Current APIKey',
                    { heading: 'Key Expiration Date', value: 'KeyExpirationDate',type:'date' }
                ]);
            })
            .catch((err)=>{
                console.log('There was an error!',err);
            });
    },[]);
    const editButtonCallback = (rowData) => {
        navigate("/admin/customer", { state: { customerId: rowData.UserId },
            replace: true,
        });
    }
    const keylogButtonCallback = (rowData) => {
        navigate("/admin/keylist", { state: { 
                    customerId: rowData.UserId, 
                    companyName: rowData.UserCompany,
                    backURL: window.location.pathname },
            replace: true,
        });
    }
    return (
        <Container className="m-1"  style={{ maxWidth: "100% !important"}}>
            <h1>Customer List</h1>
            {tableData?.length > 0 && <ListTable data={tableData} colHeads={colHeads} actionButtons={[
                { buttonName: 'edit', callback: editButtonCallback },
                { buttonName:'keyList',callback: keylogButtonCallback}
                ]} />
                } 
        </Container>

    )
}