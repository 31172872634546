import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ListTable from '../../components/ListTable/listtable';
//import { AUTH_API_BASE_URL } from '../../globals';
import { useLocation } from 'react-router-dom';
import './keylog.css'

export default function KeyLogList() {
    let location = useLocation();
    // console.log("ApiKey:",location.state?.ApiKey)
    const [customerId] = useState(location.state?.customerId);
    const [companyName] = useState(location.state?.companyName);
    const [ApiKey] = useState(location.state?.ApiKey);
    const BACKURL = location.state?.backURL;
    const [colHeads, setColHeads] = useState([
        { heading: 'Sr No.', value: 'srNo' },
        { heading: 'Requested Time', value: 'RequestedTime' },
        { heading: 'TWG Transaction Id', value: 'TransactionId' },
        { heading: 'Status Code', value: 'StatusCode' },
        { heading: 'End Point', value: 'EndPoint' },
        { heading: 'Client Request URL', value: 'ClientRequestURL' },
        { heading: 'Request Query Inputs', value: 'RequestQueryParmJSON' },
        { heading: 'Returned Version', value: 'ReturnedVersion' },
        { heading: 'Returned Code', value: 'ReturnedCode' },
        { heading: 'Returned Msg', value: 'ReturnedMsg' },
        { heading: 'Attom Transaction ID', value: 'TransactionID' },
        { heading: 'Total Matched', value: 'TotalMatched' },
        { heading: 'Page Number', value: 'PageNumber' },
        { heading: 'Num Records Returned', value: 'NumRecordsReturned' },
        { heading: 'Returned Time', value: 'ReturnedTime', type: 'date' }
    ]);
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        //get data from db for the list table
        axios.get(`${process.env.REACT_APP_AUTH_API_BASE_URL}/user/GetAPIkeyHistoryList`,
            {
                params: { "ApiKey": ApiKey }
                // headers: {
                //     'apiKey': 'TestABC123'
                // }
            })
            .then((res) => {
                setTableData(res.data);
                const data = res.data;
                data.sort(function (a, b) {
                    const keyA = new Date(a.RequestedTime),
                        keyB = new Date(b.RequestedTime);
                    // Compare the 2 dates
                    if (keyA > keyB) return -1;
                    if (keyA < keyB) return 1;
                    return 0;
                });
                data.forEach((a, i) => {
                    a.srNo = i + 1;
                });
                setColHeads([
                    { heading: 'Sr No.', value: 'srNo' },
                    { heading: 'Requested Time', value: 'RequestedTime' },
                    { heading: 'TWG Transaction Id', value: 'TransactionId' },
                    { heading: 'Status Code', value: 'StatusCode' },
                    { heading: 'End Point', value: 'EndPoint' },
                    { heading: 'Client Request URL', value: 'ClientRequestURL' },
                    { heading: 'Request Query Inputs', value: 'RequestQueryParmJSON' },
                    { heading: 'Returned Version', value: 'ReturnedVersion' },
                    { heading: 'Returned Code', value: 'ReturnedCode' },
                    { heading: 'Returned Msg', value: 'ReturnedMsg' },
                    { heading: 'Attom Transaction ID', value: 'TransactionID' },
                    { heading: 'Total Matched', value: 'TotalMatched' },
                    { heading: 'Page Number', value: 'PageNumber' },
                    { heading: 'Num Records Returned', value: 'NumRecordsReturned' },
                    { heading: 'Returned Time', value: 'ReturnedTime', type: 'date' }
                ]);
            })
            .catch((err) => {
                console.log('There was an error!', err);
            });
    }, []);

    return (
        <Container className="m-1" >
            <h1>Api Key History</h1>
            <button onClick={() => navigate(BACKURL, { state: { customerId: customerId, companyName: companyName, ApiKey: ApiKey, backURL: '/admin/customerlist' } })} class="btn btn-info">Back</button>
            <Row className="mb-0">
                <Form.Group as={Col} md="6" controlId="customerId">
                    <Form.Label className="mb-0 control-label col-sm-3 font-weight-bolder"><strong>User Id:</strong></Form.Label>
                    <span> </span>
                    <Form.Label className="mb-0">{customerId}</Form.Label>
                </Form.Group>
            </Row>
            <Row className="mb-0">
                <Form.Group as={Col} md="6" controlId="companyName">
                    <Form.Label className="mb-0 control-label col-sm-3 font-weight-bold"><strong>Company Name:</strong></Form.Label>
                    <span> </span>
                    <Form.Label className="mb-0">{companyName}</Form.Label>
                </Form.Group>
            </Row>
            <Row className="mb-0">
                <Form.Group as={Col} md="8" controlId="ApiKey">
                    <Form.Label className="mb-0 control-label col-sm-2 font-weight-bold"><strong>API Key:</strong></Form.Label>
                    <span> </span>
                    <Form.Label className="mb-0">{ApiKey}</Form.Label>
                </Form.Group>
            </Row>

            <div className="tableStyle">
                <ListTable data={tableData} colHeads={colHeads} actionButtons={[]} />
            </div>
        </Container>

    )
}