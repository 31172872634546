import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../Auth/AuthContext";
import './login.css'

function LoginPage() {
    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();
    let [validlogin,setValidLogin] = useState(true);
    let from = location.state?.from?.pathname || "/admin/customerlist";

    function handleSubmit(event) {
        event.preventDefault();

        let formData = new FormData(event.currentTarget);
        let username = formData.get("username");
        let password = formData.get('password');
        
        auth.signin({ "username": username, "password": password }, (res) => {
            // Send them back to the page they tried to visit when they were
            // redirected to the login page. Use { replace: true } so we don't create
            // another entry in the history stack for the login page.  This means that
            // when they get to the protected page and click the back button, they
            // won't end up back on the login page, which is also really nice for the
            // user experience.
            if(res.isAuth)
                navigate(from, { replace: true });
            else
                setValidLogin(false);
        });
    }


    return (
        <div className="login-container">
            <div className="login-form-2">
            <h2>TWG Login</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="username">
                    <Form.Label>User Name</Form.Label>
                    <Form.Control className="input-lg" type="text"
                        name="username" placeholder="User Name" >
                    </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control className="input-lg" type="password"
                        name="password"
                        placeholder="Password">
                    </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label id='lblerror' style={{display: validlogin ? 'none' : 'block' }} className='clserrorLabel'>Username and password invalid</Form.Label>
                    <Form.Control type="submit" name="submit" className="btn btn-primary btn-lg"
                        value="Login">
                    </Form.Control>
                </Form.Group>
            </Form>
            </div>
        </div>
    );
}
export default LoginPage;
