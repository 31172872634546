// import React from 'react';
import { Form,Row,Col,Button } from 'react-bootstrap';
import React, {  useEffect, useState,useRef } from 'react';
import './customer.css'
import axios from 'axios';
//import { AUTH_API_BASE_URL } from '../../globals';
import DateTimePicker from 'react-datetime-picker';
import { useLocation, useParams,useNavigate } from 'react-router-dom';


function CustomerAdd() {
  let location = useLocation();
  const [customerId, setCustomerId]  = useState(location.state?.customerId);
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const HeaderName = customerId > 0 ? 'Edit Customer' : 'Add Customer';
  let [customer,setCustomer] = useState(
    {}
    );
    useEffect(() => {
      if (customerId > 0){
          //get Customer data from db for bind edit form
          axios.get(`${process.env.REACT_APP_AUTH_API_BASE_URL}/user/GetCustomerDetailsById`,
          {
                params: {"customerId" : customerId},
                headers: {
                    'apiKey': 'TestABC123',
                }
          })
          .then((res) => {
              setCustomer(res.data);
          })
          .catch((err)=>{
              console.log('There was an error!',err);
          });
      }
    },[]);
 
  // let from = location.state?.from?.pathname || "/";

  const handleSubmit = (event) => {
      event.preventDefault();
      let formData = new FormData(event.target);
      // let fName = formData.get("firstname");
      // let password = formData.get('password');
      // print("customer handleSubmit call")
      const objcustomer={
            "UserId":customerId > 0 ?  customerId : -1,
            "UserName":"testuser2",
            "Password":"test@123",
            "Secret":"",
            "UserFirstName":formData.get("txtUserFirstName"),
            "UserLastName":formData.get("txtUserLastName"),
            "UserCompany":formData.get("txtUserCompany"),
            "ReferenceID":formData.get("txtReferenceID"),
            "AccountDuration":formData.get("drpAccountDuration"),
            "AutoRenew":formData.get("drpAutoRenew"),
            "MaxRecordsPerQuery":formData.get("txtMaxRecordsPerQuery"),
            "MaxRecordsPerDay":formData.get("txtMaxRecordsPerDay"),
            "MaxRecordsPerKeyCycle":formData.get("txtMaxRecordsPerKeyCycle"),
            "RoleId":2,
            "NextTierAccessKey":formData.get("drpNextTierAccessKey"),
            "AccountTypeAccessKey": formData.get("drpAccountTypeAccessKey"),
            "StartDate": formData.get("txtStartDate"),
            "Status": formData.get("drpStatus"),
            "isGenerateAPIKey":false
            }
      axios.post(`${process.env.REACT_APP_AUTH_API_BASE_URL}/user/CustomerAddEdit`,objcustomer,{
        headers: {
          // 'apiKey':'TestABC123',
          // 'Content-Type' : 'multipart/form-data'
        }
      })
      .then((res)=>{
        if(res.data.status === "success"){
          navigate("/admin/customerlist", { replace: true});
        }
      })
      .catch((err)=>{
        console.log('There was an error!',err);
      });

      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }

      setValidated(true);
  }

  //defain datechange event for sorting date column
  const [value, onDateChange] = useState(new Date());

  const inputUserFirstName = useRef(customer.UserFirstName);
  const handleChange = (name, e)=>{
    var customerchanged = {};
    customerchanged[name] = e.target.value;
    setCustomer(customerchanged);
  }

  const GenerateButtonClick = (name, e) => {
    axios.post(`${process.env.REACT_APP_AUTH_API_BASE_URL}/user/GenerateAPIKey?custId=`+customerId,{
      // headers: {
      //   'apiKey':'TestABC123'
      // }
    })
    .then((res)=>{
      if(res.data.status === "success"){
        navigate("/admin/customerlist", { replace: true});
      }
    })
    .catch((err)=>{
      console.log('There was an error!',err);
    });
  };


  let btnGenerateAPI;
  if (customerId > 0) {
    btnGenerateAPI = <Button name="btnGenerateKey" type="button" onClick={GenerateButtonClick}>Generate Key</Button>;
  } 

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit} className="form-content-padding-LeftRight">
    <h1>{HeaderName}</h1>
    <Row className="mb-3">
      <Form.Group as={Col} md="6" controlId="UserFirstName">

        <Form.Label><span className="required-asterisk">*</span>First name</Form.Label>
        <Form.Control
          name="txtUserFirstName"
          required
          type="text"
          placeholder="First name"
          value={customer.UserFirstName}
          onChange={handleChange.bind('UserFirstName',this)}
        />
        <Form.Control.Feedback>Validated</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md="6" controlId="UserLastName">
        <Form.Label><span className="required-asterisk">*</span>Last name</Form.Label>
        <Form.Control
          name="txtUserLastName"
          required
          type="text"
          placeholder="Last name"
          value={customer.UserLastName}
          onChange={handleChange.bind('UserLastName',this)}
        />
        <Form.Control.Feedback>Validated</Form.Control.Feedback>
      </Form.Group>
    </Row>
    <Row className="mb-3">
      <Form.Group as={Col} md="6" controlId="UserCompany">
        <Form.Label><span className="required-asterisk">*</span>Company Name</Form.Label>
        <Form.Control
          name="txtUserCompany"
          required
          type="text"
          placeholder="Company Name"
          value={customer.UserCompany}
          onChange={handleChange.bind('UserCompany',this)}
        />
        <Form.Control.Feedback>Validated</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md="6" controlId="ReferenceID">
        <Form.Label><span className="required-asterisk">*</span>Reference ID</Form.Label>
        <Form.Control
          name="txtReferenceID"
          required
          type="text"
          placeholder="Reference ID"
          value={customer.ReferenceID}
          onChange={handleChange.bind('ReferenceID',this)}
        />
        <Form.Control.Feedback>Validated</Form.Control.Feedback>
      </Form.Group>
    </Row>
    <Row className="mb-3">
      <Form.Group as={Col} md="6" controlId="TierAccessKey">
        <Form.Label><span className="required-asterisk">*</span>Account Type</Form.Label>
        <Form.Select name="drpAccountTypeAccessKey" value={customer.TierAccessKey} required onChange={handleChange.bind('TierAccessKey',this)}>
          <option value="">Select Contract</option>
          <option value="Basic">Basic</option>
          <option value="Premium">Premium</option>
          <option value="AVM">AVM</option>
        </Form.Select>
        <Form.Control.Feedback>Validated</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md="6" controlId="NextTierAccessKey">
        <Form.Label><span className="required-asterisk">*</span>Next Tier Account Type</Form.Label>
        <Form.Select name="drpNextTierAccessKey"  value={customer.NextTierAccessKey}  required onChange={handleChange.bind('NextTierAccessKey',this)}>
          <option value="">Select Contract</option>
          <option value="Basic">Basic</option>
          <option value="Premium">Premium</option>
          <option value="AVM">AVM</option>
        </Form.Select>
        <Form.Control.Feedback>Validated</Form.Control.Feedback>
      </Form.Group>
    </Row>
    <Row className="mb-3">
      <Form.Group as={Col} md="4" controlId="AccountDuration">
        <Form.Label><span className="required-asterisk">*</span>Account Duration</Form.Label>
        <Form.Select name="drpAccountDuration"  value={customer.AccountDuration}  required onChange={handleChange.bind('AccountDuration',this)}>
          <option value="">Select</option>
          <option value="1DAY">1 DAY</option>
          <option value="7DAYS">7 DAYS</option>
          <option value="1MN">1 Month</option>
          <option value="3MN">3 Month</option>
          <option value="12MN">12 Month</option>
        </Form.Select>
        <Form.Control.Feedback>Validated</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md="4" controlId="StartDate">
        <Form.Label><span className="required-asterisk">*</span>Start Date</Form.Label>
        {/* <Form.Control
          required
          type="text"
          placeholder="Start Date"
        /> */}
        <DateTimePicker name="txtStartDate" className={"form-control"} onChange={onDateChange} value={customer.StartDate !== undefined ? customer.StartDate : value} required/>
        <Form.Control.Feedback>Validated</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md="4" controlId="Status">
        <Form.Label><span className="required-asterisk">*</span>Status</Form.Label>
        <Form.Select name="drpStatus" value={customer.Status} required onChange={handleChange.bind('Status',this)}>
          <option value="A">Active</option>
          <option value="I">Inactive</option>
        </Form.Select>
        <Form.Control.Feedback>Validated</Form.Control.Feedback>
      </Form.Group>
    </Row>
    <Row className="mb-3 user-Accessibility-Header">
      <Form.Group as={Col} md="12" controlId="validationCustom01">
        <Form.Label>User Accessibility</Form.Label>
      </Form.Group>
    </Row>
    <Row className="mb-3">
      <Form.Group as={Col} md="4" controlId="MaxRecordsPerQuery">
        <Form.Label><span className="required-asterisk">*</span>Max. Records Per Query</Form.Label>
        <Form.Control
          name="txtMaxRecordsPerQuery"
          required
          type="number"
          placeholder="Max. Records Per Query"
          defaultValue="50"
          maxLength={9}
          value={customer.MaxRecordsPerQuery}
          onChange={handleChange.bind('MaxRecordsPerQuery',this)} 
          // pattern="[+-]?\d+(?:[.,]\d+)?"
          // onChange={this.handleDepositeAmountChange}
        />
        <Form.Control.Feedback>Validated</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md="4" controlId="MaxRecordsPerDay">
        <Form.Label><span className="required-asterisk">*</span>Max. Records Per Day</Form.Label>
        <Form.Control
          name="txtMaxRecordsPerDay"
          required
          type="number"
          placeholder="Max. Records Per Day"
          defaultValue="500"
          value={customer.MaxRecordsPerDay}
          onChange={handleChange.bind('MaxRecordsPerDay',this)}
        />
        <Form.Control.Feedback>Validated</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md="4" controlId="MaxRecordsPerKeyCycle">
        <Form.Label><span className="required-asterisk">*</span>Max. Records Per Key Cycle</Form.Label>
        <Form.Control
          name="txtMaxRecordsPerKeyCycle"
          required
          type="number"
          placeholder="Max. Records Per Key Cycle"
          defaultValue="500"
          value={customer.MaxRecordsPerKeyCycle}
          onChange={handleChange.bind('MaxRecordsPerKeyCycle',this)}
        />
        <Form.Control.Feedback>Validated</Form.Control.Feedback>
      </Form.Group>
    </Row>
    <Row className="mb-3">
      <Form.Group as={Col} md="4" controlId="AutoRenew">
        <Form.Label><span className="required-asterisk">*</span>Auto Renew</Form.Label>
        <Form.Select name="drpAutoRenew" value={customer.AutoRenew} required onChange={handleChange.bind('AutoRenew',this)}>
          <option value="Y">Yes</option>
          <option value="N">No</option>
        </Form.Select>
      </Form.Group>
    </Row>
    <Button name="btnSubmit" type="submit">Submit</Button>
    <span> </span>
    {btnGenerateAPI}
  </Form>
  );

  

}


export default CustomerAdd;
