import { Col, Container, Row } from 'react-bootstrap';
import { Outlet } from "react-router-dom";
import SideBar from '../SideBar/sidebar';
import './adminlayout.css';
function AdminLayout() {
    const sblinks = [{link:'Customer List',isBookMark:false,href:'/admin/customerlist'}, 
    {link:'New Customer',isBookMark:false,href:'/admin/customer'}];
    return (
        <div>
            <Container fluid>
                <Row>
                    <div className="p-0 leftnav">
                        <SideBar sblinks={sblinks} />
                    </div>
                    <div className="p-2 rightdiv">
                        <Outlet />
                    </div>
                </Row>
            </Container>
        </div>
    );
}

export default AdminLayout;
