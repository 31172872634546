import { Route, Routes, Navigate } from 'react-router-dom';
import AdminLayout from './components/AdminLayout/adminlayout';
import { fakeAuthProvider, authProvider } from './components/Auth/auth';
import AuthProvider from './components/Auth/AuthProvider';
import RequireAuth from './components/Auth/RequireAuth';
import DocsLayout from './components/DocsLayout/docslayout';
import Home from './components/HomePage/home';
import Layout from './components/Layout/Layout';
import LoginPage from './components/Login/Login';
import CustomerList from './routes/customerlist/customerlist';
import ApiDocumentation from './routes/documentation';
import Customer from './routes/customer/customer';
import KeyList from './routes/keylist/keylist';
import KeyLogList from './routes/keylog/keyloglist';
function App() {
  return (
    // <AuthProvider authProvider={fakeAuthProvider}>
    <AuthProvider authProvider={authProvider}>


      <Routes>
        {/* <Route path="/" element={<Layout />} > */}
        <Route path="documentation" element={<DocsLayout> <ApiDocumentation /></DocsLayout>} />
        {/* </Route> */}
        <Route element={<RequireAuth role="admin"><Layout /></RequireAuth>}>
          <Route path="/" element={<Navigate to={'admin/customerlist'} />} />
          {/* <Route path="documentation" element={<DocsLayout> <ApiDocumentation /></DocsLayout>} /> */}
          <Route path="admin" element={<AdminLayout />} >
            <Route path="customerlist" element={<CustomerList />} />
            <Route path="customer" element={<Customer />} />
            <Route path="keylist" element={<KeyList />} />
            <Route path="keyloglist" element={<KeyLogList />} />
          </Route>

        </Route>
        <Route path="/login" element={<LoginPage />} />

      </Routes>


    </AuthProvider >
  );
}

export default App;
