import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import { AUTH_API_DOCS_PATH } from '../globals';

export default function ApiDocumentation(props) {
        
    return(
        <>
            <h1>Documentation</h1>
            <br></br>
            <SwaggerUI url={AUTH_API_DOCS_PATH} />
            {/* <SwaggerUI url={`${process.env.REACT_APP_AUTH_API_BASE_URL}/2openapi.json`} /> */}
        </>
    );
}