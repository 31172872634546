import React from 'react';
import { Container, Nav } from 'react-bootstrap';
import { Outlet, Link } from 'react-router-dom';
import './sidebar.css'
export default function SideBar(props) {
    const viewHeight = window.outerHeight;
    let navLinks = []
    navLinks =
        props.sblinks.map((link,index) => {
            if (link.isBookMark) {
                return <Nav.Link key={index} href={link.href}>{link.link.toUpperCase()}</Nav.Link>
            } else {
                return <Nav.Link key={index} as={Link} to={link.href}>{link.link.toUpperCase()}</Nav.Link>
            }
        });
    return (

        <Nav style={{ height: viewHeight }} className="flex-column sb-width bglight">
            {/* <Nav.Link as={Link} to="/">Home</Nav.Link>
                <Nav.Link href="#operations-tag-pet">Pets</Nav.Link>
                <Nav.Link href="#operations-tag-store">Store</Nav.Link> */}
            {navLinks}
        </Nav>


    )
}

