import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form,Row,Col,Container } from 'react-bootstrap';
import { useNavigate  } from 'react-router-dom';
import ListTable from '../../components/ListTable/listtable';
//import { AUTH_API_BASE_URL } from '../../globals';
import { useLocation } from 'react-router-dom';

export default function KeyList() {
    let location = useLocation();
    const [customerId]  = useState(location.state?.customerId);
    const [companyName]  = useState(location.state?.companyName);
    const BACKURL = location.state?.backURL;
    const [colHeads, setColHeads] = useState([
        { heading:'User Id',value: 'UserId'},
        { heading:'Api Key',value: 'TWGApiKey'},
        { heading:'Status',value: 'Status'},
        { heading:'From Date',value: 'FromDate',type:'date'},
        { heading:'End Date',value: 'EndDate',type:'date'},
    ]);
    const [tableData, setTableData] = useState([]);
    let [ApiError,setApiError] = useState(false);
    // const [tableData, setTableData] = useState([
    //     { ApiKey: "Sdhalddlnwpwsds", Status: 'Active', FromDate: '2022-06-20T16:38:21.865795+05:30',EndDate: '2022-06-20T16:38:21.865795+05:30'},
    //     { ApiKey: "Sdhalddlasdnklsndlnnwpwsds", Status: 'Active', FromDate: '2022-06-20T16:38:21.865795+05:30',EndDate: '2022-06-20T16:38:21.865795+05:30'},
    //     { ApiKey: "Sdhalddaskndnsdlnwpwsds", Status: 'InActive', FromDate: '2022-06-20T16:38:21.865795+05:30',EndDate: '2022-06-20T16:38:21.865795+05:30'},
    //     { ApiKey: "Sdhaldasldlsddlnwpwsds", Status: 'Active', FromDate: '2022-06-20T16:38:21.865795+05:30',EndDate: '2022-06-20T16:38:21.865795+05:30'},
    //     { ApiKey: "Sdhalddbb233lnwpwsds", Status: 'InActive', FromDate: '2022-06-20T16:38:21.865795+05:30',EndDate: '2022-06-20T16:38:21.865795+05:30'}
    // ]);
    // const [colHeads, setColHeads] = useState(['API Key',
    //                                             'Status',
    //                                             'From Date',
    //                                             'End Date']);
    const navigate = useNavigate();
    useEffect(() => {
        //get data from db for the list table
        axios.get(`${process.env.REACT_APP_AUTH_API_BASE_URL}/user/GetCustomerAPIkeyList`,
        {
            params: {"customerId" : customerId},
            headers: {
                'apiKey': 'TestABC123'
            }
        })
        .then((res) => {
            setTableData(res.data);
            setColHeads([
                { heading:'User Id',value: 'UserId'},
                { heading:'Api Key',value: 'TWGApiKey'},
                { heading:'Status',value: 'Status'},
                { heading:'From Date',value: 'FromDate',type:'date'},
                { heading:'End Date',value: 'EndDate',type:'date'},
            ]);
        })
        .catch((err)=>{
            console.log('There was an error!',err);
        });
    },[]);
    const viewHistoryCallback = (rowData) => {
        navigate("/admin/keyloglist", { state: {customerId:customerId, companyName:companyName, ApiKey: rowData.TWGApiKey,backURL: window.location.pathname  },
            replace: true,
        });
    }
    //let history = useHistory();
    return (
        <Container className="m-1">
            <h1>Key List</h1>
            
            <Row className="mb-0">
                <Form.Group as={Col} md="6" controlId="UserFirstName">
                    <Form.Label className="mb-0 control-label col-sm-3 font-weight-bolder"><strong>User Id:</strong></Form.Label>
                    <span> </span>
                    <Form.Label className="mb-0">{customerId}</Form.Label>
                </Form.Group>
            </Row>
            <Row className="mb-0">
                <Form.Group as={Col} md="6" controlId="UserFirstName">
                    <Form.Label className="mb-0 control-label col-sm-3 font-weight-bold"><strong>Company Name:</strong></Form.Label>
                    <span> </span>
                    <Form.Label className="mb-0">{companyName}</Form.Label>
                </Form.Group>
            </Row>
            <button onClick={() => navigate(BACKURL)} class="btn btn-info">Back</button>
            <ListTable  data={tableData} colHeads={colHeads} actionButtons={[
                { buttonName: 'keyHistory', callback: viewHistoryCallback },
                ]} />
        </Container>

    )
}