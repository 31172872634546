import { Navigate, useLocation } from 'react-router-dom';
// import { isPropertySignature } from 'typescript';
import useAuth from './AuthContext';

function RequireAuth({ children }) {
    let auth = useAuth();
    let location = useLocation();
    
    //Begin get user details from Local storage 
    let user = localStorage.getItem("username");
    let userrole = localStorage.getItem("role");
    //End get user details from Local storage 
    let isValidatUser = true;
    if(auth.user == null &&  user == null && userrole == null)
      isValidatUser = false;
    else if(auth.user != null && !auth.user.isAuth )
      isValidatUser = false;
    //check user details from Local storage and validate
    if (!isValidatUser) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      if(location.pathname === "/")
        location = "/admin/customerlist";
      return <Navigate to="/login" state={{ from: location ,isAuth:0}} />;
    }
  
    return children;
  }
  export default RequireAuth;