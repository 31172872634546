import React, {useEffect} from 'react';
import { red } from 'react-router-dom';
function Home() {
    
    
    return (
        <>
        <div>Main Page</div>
        </>
    )
}
export default Home;