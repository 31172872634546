import { Nav, Navbar } from 'react-bootstrap';
import { Link } from "react-router-dom";
import AuthStatus from "../Auth/AuthStatus";

function Header() {
    return (

        <>
            <Navbar bg="dark" variant="dark" sticky="top" className="p-3 shadow">

                <Navbar.Brand as={Link} to="/admin/customerlist">TWG</Navbar.Brand>
                {/* <Navbar.Brand as={Link} to="/">TWG</Navbar.Brand>
                <Nav className="me-auto">
                    <Nav.Link as={Link} to="/documentation">API Documentation</Nav.Link>
                    <Nav.Link as={Link} to="/admin">Admin</Nav.Link>
                </Nav> */}
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        <AuthStatus />
                    </Navbar.Text>
                </Navbar.Collapse>

            </Navbar>

        </>

    );
}



export default Header;