import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import useAuth from './AuthContext';

function AuthStatus() {
    let auth = useAuth();
    let navigate = useNavigate();

    //Begin get user details from Local storage 
    let user = localStorage.getItem("username");
    let userrole = localStorage.getItem("role");
    //End get user details from Local storage 
    let isValidatUser = true;
    if(auth.user == null &&  user == null && userrole == null)
        isValidatUser = false;
    else if(auth.user != null && !auth.user.isAuth )
        isValidatUser = false;
    //check user details from Local storage 
    if (!isValidatUser) {
        return (
            <Nav.Link as={Link} to="/login">Login</Nav.Link>
        );
    }

    return (
        <>
            
            <NavDropdown title={user} id="basic-nav-dropdown"
                menuVariant="dark" align="end"
            >
                <NavDropdown.Item onClick={() => {
                    auth.signout(() => navigate("/"));
                }} >Sign Out
                </NavDropdown.Item>
            </NavDropdown>
            {/* <Button
                onClick={() => {
                    auth.signout(() => navigate("/"));
                }}
            >
                Sign out
            </Button> */}
        </>
    );
}

export default AuthStatus;
