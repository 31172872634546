// const AUTH_API_BASE_URL = "http://localhost:8000";
// const AUTH_API_BASE_URL = "https://apidev.thewarrengroup.com/api";
const AUTH_API_BASE_URL = process.env.REACT_APP_AUTH_API_BASE_URL;
const AUTH_API_LOGIN_PATH = '/user/login';
// const AUTH_API_LOGOUT_PATH = "https://apidev.thewarrengroup.com/api";
const AUTH_API_LOGOUT_PATH = process.env.REACT_APP_AUTH_API_BASE_URL;
// const AUTH_API_DOCS_PATH = "https://petstore.swagger.io/v2/swagger.json";
// const AUTH_API_DOCS_PATH = AUTH_API_BASE_URL+"/openapi.json";
const AUTH_API_DOCS_PATH = process.env.REACT_APP_AUTH_API_BASE_URL+"/openapi.json";


const sbLinks = {
    '/admin':[{link:'Customer List',isBookMark:false,href:'/admin/customerlist'}, 
    {link:'New Customer',isBookMark:false,href:'/admin/customer'}],
    '/documentation':[{link:'pets',isBookMark:true,href:'#operations-tag-pets'},
    {link:'store',isBookMark:true,href:'#operations-tag-store'}]
}


export {AUTH_API_BASE_URL,
    AUTH_API_LOGIN_PATH,
    AUTH_API_LOGOUT_PATH,
    AUTH_API_DOCS_PATH,
    sbLinks
}