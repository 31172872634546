import axios from 'axios';
// import { AUTH_API_BASE_URL } from '../../globals';
import React, {  useEffect } from 'react';

 const fakeAuthProvider = {
    isAuthenticated: false,
    signin(cred, callback) {
      fakeAuthProvider.isAuthenticated = true;
      setTimeout(callback({"username":cred.username,"role":"admin"}), 100); // fake async
    },
    signout(callback) {
      fakeAuthProvider.isAuthenticated = false;
      setTimeout(callback, 100);
    },
  };
  

  const authProvider = {
    isAuthenticated: false,
    signin(cred, callback) {
      console.log("login 0");
      debugger;
      // useEffect(() => {
        // axios.post(AUTH_API_BASE_URL+"/user/login",cred,{
        //   // headers: {
        //   //   'apiKey':'TestABC123'
        //   // }
        // })
        //console.log(process.env.REACT_APP_AUTH_API_BASE_URL);
        console.log(`${process.env.REACT_APP_AUTH_API_BASE_URL}/user/login`);
        axios.post(`${process.env.REACT_APP_AUTH_API_BASE_URL}/user/login`, cred, {
          // axios.post(AUTH_API_BASE_URL+"/user/login",cred,{
            // headers: {
            //   'apiKey':'TestABC123'
            // }
        })
        .then((res)=>{
          console.log("login 1");
          const user = res.data;  
          if(user.isAuth === 1) {
              authProvider.isAuthenticated = true;
              //Begin set user details in Local storage 
              setTimeout(callback({"username":user.username,"role":user.role,isAuth:1}),3000);
              localStorage.setItem("username", user.username);
              localStorage.setItem("role", user.role);
              //End set user details in Local storage 
            }
            else{
              authProvider.isAuthenticated = false;
              callback({"username":'',"role":'',isAuth:0});
            }
            console.log("login 2");
        })
        .catch((err)=>{
          console.log("login 33333");
          console.log('There was an error!',err);
          callback({"username":'',"role":'',isAuth:0});
        });
      // },[]);
    },
    signout(callback) {
      authProvider.isAuthenticated = false;
      //Begin remove user details in Local storage 
      localStorage.removeItem("username");
      localStorage.removeItem("role");
      //End remove user details in Local storage 
      callback();
    },
  };

  export { fakeAuthProvider,authProvider };
  